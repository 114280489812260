.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}
body {
  background-color: #fff;
}
body,
button,
input,
textarea,
select {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #000;
}
a,
button,
input,
textarea,
select {
  transition: 0.2s ease;
}
a {
  color: #003764;
  text-decoration: none;
}
a.a-classic {
  text-decoration: underline;
}
a:hover {
  cursor: pointer;
}
button:hover,
select:hover,
label:hover,
#checkbox:hover,
input[type='radio']:hover {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  margin-bottom: 20px;
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
}
p {
  margin-bottom: 20px;
}
ul li {
  position: relative;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #151e2f;
  padding-left: 15px;
  margin-bottom: 5px;
}

h1,
.h1 {
  font-size: 32px;
  line-height: 36px;
}
@media (min-width: 960px) {
  h1,
  .h1 {
    font-size: 50px;
    line-height: 56px;
  }
}
h2,
.h2 {
  font-size: 26px;
  line-height: 29px;
}
@media (min-width: 720px) {
  h2,
  .h2 {
    font-size: 32px;
    line-height: 36px;
  }
}
h3,
.h3 {
  font-size: 20px;
  line-height: 22px;
}
@media (min-width: 480px) {
  h3,
  .h3 {
    font-size: 26px;
    line-height: 29px;
  }
}
h4,
.h4 {
  font-size: 16px;
  line-height: 22px;
}
@media (min-width: 480px) {
  h4,
  .h4 {
    font-size: 20px;
    line-height: 22px;
  }
}
h5,
.h5 {
  font-size: 14px;
  line-height: 16px;
}
ul,
ol {
  list-style-type: none;
}
img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}
.row {
  margin-left: -10px;
  margin-right: -10px;
}
.row:before,
.row:after {
  content: " ";
  display: table;
}
.row:after {
  clear: both;
}
.cols {
  margin-left: -10px;
  margin-right: -10px;
}
.cols:before,
.cols:after {
  content: " ";
  display: table;
}
.cols:after {
  clear: both;
}
.cols>div {
  margin-bottom: 20px;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}
.cols:last-child {
  margin-bottom: -20px;
}
.cols.cols--0 {
  margin-left: 0;
  margin-right: 0;
}
.cols.cols--0:before,
.cols.cols--0:after {
  content: " ";
  display: table;
}
.cols.cols--0:after {
  clear: both;
}
.cols.cols--0>div {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.cols.cols--0:last-child {
  margin-bottom: 0;
}
.cols.cols--sm {
  margin-left: -5px;
  margin-right: -5px;
}
.cols.cols--sm:before,
.cols.cols--sm:after {
  content: " ";
  display: table;
}
.cols.cols--sm:after {
  clear: both;
}
.cols.cols--sm>div {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
.cols.cols--sm:last-child {
  margin-bottom: -10px;
}
@media (max-width: 720px) {
  .cols.cols--scroll {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    margin: 0 -20px -20px;
    padding: 0 20px 20px;
  }
  .cols.cols--scroll>div {
    display: inline-block;
    vertical-align: top;
    width: 280px;
    white-space: normal;
    margin: 0;
    padding: 0 5px 0 0;
  }
  .cols.cols--scroll>div:last-child {
    margin-right: 15px;
  }
}
@media (max-width: 720px) {
  .cols.cols--min {
    margin-left: 0;
    margin-right: 0;
    margin-left: -5px;
    margin-right: -5px;
  }
  .cols.cols--min:before,
  .cols.cols--min:after {
    content: " ";
    display: table;
  }
  .cols.cols--min:after {
    clear: both;
  }
  .cols.cols--min>div {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
  .cols.cols--min:last-child {
    margin-bottom: -10px;
  }
}
@media (min-width: 960px) {
  .cols.cols--md {
    margin-left: -20px;
    margin-right: -20px;
  }
  .cols.cols--md:before,
  .cols.cols--md:after {
    content: " ";
    display: table;
  }
  .cols.cols--md:after {
    clear: both;
  }
  .cols.cols--md>div {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
  }
  .cols.cols--md:last-child {
    margin-bottom: -40px;
  }
}
.cols .col-xs-p20 {
  width: 20%;
  float: left;
}
@media (min-width: 480px) {
  .cols .col-sm-p20 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 720px) {
  .cols .col-md-p20 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 960px) {
  .cols .col-lg-p20 {
    width: 20%;
    float: left;
  }
}
.cols .col-xs-p12 {
  width: 12.5%;
  float: left;
}
@media (min-width: 480px) {
  .cols .col-sm-p12 {
    width: 12.5%;
    float: left;
  }
}
@media (min-width: 720px) {
  .cols .col-md-p12 {
    width: 12.5%;
    float: left;
  }
}
@media (min-width: 960px) {
  .cols .col-lg-p12 {
    width: 12.5%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .cols .col-xl-6 {
    width: 50%;
    float: left;
  }
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666666666666%;
}
.col-xs-10 {
  width: 83.33333333333334%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666666666666%;
}
.col-xs-7 {
  width: 58.333333333333336%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666666666667%;
}
.col-xs-4 {
  width: 33.33333333333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.666666666666664%;
}
.col-xs-1 {
  width: 8.333333333333332%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666666666666%;
}
.col-xs-pull-10 {
  right: 83.33333333333334%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666666666666%;
}
.col-xs-pull-7 {
  right: 58.333333333333336%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666666666667%;
}
.col-xs-pull-4 {
  right: 33.33333333333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.666666666666664%;
}
.col-xs-pull-1 {
  right: 8.333333333333332%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666666666666%;
}
.col-xs-push-10 {
  left: 83.33333333333334%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666666666666%;
}
.col-xs-push-7 {
  left: 58.333333333333336%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666666666667%;
}
.col-xs-push-4 {
  left: 33.33333333333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.666666666666664%;
}
.col-xs-push-1 {
  left: 8.333333333333332%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666666666666%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333333334%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666666666666%;
}
.col-xs-offset-7 {
  margin-left: 58.333333333333336%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666666666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.666666666666664%;
}
.col-xs-offset-1 {
  margin-left: 8.333333333333332%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
@media (min-width: 480px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666666666666%;
  }
  .col-sm-10 {
    width: 83.33333333333334%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666666666666%;
  }
  .col-sm-7 {
    width: 58.333333333333336%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666666666667%;
  }
  .col-sm-4 {
    width: 33.33333333333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.666666666666664%;
  }
  .col-sm-1 {
    width: 8.333333333333332%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666666666666%;
  }
  .col-sm-pull-10 {
    right: 83.33333333333334%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666666666666%;
  }
  .col-sm-pull-7 {
    right: 58.333333333333336%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666666666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.666666666666664%;
  }
  .col-sm-pull-1 {
    right: 8.333333333333332%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666666666666%;
  }
  .col-sm-push-10 {
    left: 83.33333333333334%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666666666666%;
  }
  .col-sm-push-7 {
    left: 58.333333333333336%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666666666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.666666666666664%;
  }
  .col-sm-push-1 {
    left: 8.333333333333332%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666666666666%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333333334%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666666666666%;
  }
  .col-sm-offset-7 {
    margin-left: 58.333333333333336%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666666666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.666666666666664%;
  }
  .col-sm-offset-1 {
    margin-left: 8.333333333333332%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 720px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666666666666%;
  }
  .col-md-10 {
    width: 83.33333333333334%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666666666666%;
  }
  .col-md-7 {
    width: 58.333333333333336%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666666666667%;
  }
  .col-md-4 {
    width: 33.33333333333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.666666666666664%;
  }
  .col-md-1 {
    width: 8.333333333333332%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666666666666%;
  }
  .col-md-pull-10 {
    right: 83.33333333333334%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666666666666%;
  }
  .col-md-pull-7 {
    right: 58.333333333333336%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666666666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.666666666666664%;
  }
  .col-md-pull-1 {
    right: 8.333333333333332%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666666666666%;
  }
  .col-md-push-10 {
    left: 83.33333333333334%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666666666666%;
  }
  .col-md-push-7 {
    left: 58.333333333333336%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666666666667%;
  }
  .col-md-push-4 {
    left: 33.33333333333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.666666666666664%;
  }
  .col-md-push-1 {
    left: 8.333333333333332%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666666666666%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333333334%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666666666666%;
  }
  .col-md-offset-7 {
    margin-left: 58.333333333333336%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666666666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.666666666666664%;
  }
  .col-md-offset-1 {
    margin-left: 8.333333333333332%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 960px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666666666666%;
  }
  .col-lg-10 {
    width: 83.33333333333334%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666666666666%;
  }
  .col-lg-7 {
    width: 58.333333333333336%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666666666667%;
  }
  .col-lg-4 {
    width: 33.33333333333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.666666666666664%;
  }
  .col-lg-1 {
    width: 8.333333333333332%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666666666666%;
  }
  .col-lg-pull-10 {
    right: 83.33333333333334%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666666666666%;
  }
  .col-lg-pull-7 {
    right: 58.333333333333336%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666666666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.666666666666664%;
  }
  .col-lg-pull-1 {
    right: 8.333333333333332%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666666666666%;
  }
  .col-lg-push-10 {
    left: 83.33333333333334%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666666666666%;
  }
  .col-lg-push-7 {
    left: 58.333333333333336%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666666666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.666666666666664%;
  }
  .col-lg-push-1 {
    left: 8.333333333333332%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666666666666%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333333334%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666666666666%;
  }
  .col-lg-offset-7 {
    margin-left: 58.333333333333336%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666666666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.666666666666664%;
  }
  .col-lg-offset-1 {
    margin-left: 8.333333333333332%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
@-webkit-keyframes slideFadeUp {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
}
@keyframes slideFadeUp {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
}
@-webkit-keyframes slideUpOut {
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@keyframes slideUpOut {
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@-webkit-keyframes slideFadeDown {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
}
@keyframes slideFadeDown {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes slideDownOut {
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
@keyframes slideDownOut {
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@-webkit-keyframes slideFadeLeft {
  0% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    opacity: 0;
  }
}
@keyframes slideFadeLeft {
  0% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    opacity: 0;
  }
}
@-webkit-keyframes slideLeftOut {
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@keyframes slideLeftOut {
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@-webkit-keyframes slideFadeLeftSVG {
  0% {
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
    opacity: 0;
  }
}
@keyframes slideFadeLeftSVG {
  0% {
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
    opacity: 0;
  }
}
@-webkit-keyframes slideFadeRightSVG {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
}
@keyframes slideFadeRightSVG {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
  }
}
@-webkit-keyframes slideFadeUpBack {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes slideFadeUpBack {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@keyframes slideRight {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@-webkit-keyframes slideFadeRight {
  0% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
    opacity: 0;
  }
}
@keyframes slideFadeRight {
  0% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes slideRightOut {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@keyframes slideRightOut {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes scaleIn {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes scaleFadeIn {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes scaleFadeIn {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
}
@-webkit-keyframes scaleOut {
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes scaleOut {
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes scaleFadeOut {
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes scaleFadeOut {
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes translateBg {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@keyframes translateBg {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@-webkit-keyframes heightMd {
  0% {
    max-height: 0;
    overflow: hidden;
  }
  100% {
    max-height: 500px;
    overflow: hidden;
  }
}
@keyframes heightMd {
  0% {
    max-height: 0;
    overflow: hidden;
  }
  100% {
    max-height: 500px;
    overflow: hidden;
  }
}
@-webkit-keyframes bgIn {
  0% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
}
@keyframes bgIn {
  0% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%);
  }
  30% {
    -webkit-transform: translate(-50%, -70%);
            transform: translate(-50%, -70%);
  }
  60% {
    -webkit-transform: translate(-50%, -80%);
            transform: translate(-50%, -80%);
  }
  100% {
    -webkit-transform: translate(-50%, -90%);
            transform: translate(-50%, -90%);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%);
  }
  30% {
    -webkit-transform: translate(-50%, -70%);
            transform: translate(-50%, -70%);
  }
  60% {
    -webkit-transform: translate(-50%, -80%);
            transform: translate(-50%, -80%);
  }
  100% {
    -webkit-transform: translate(-50%, -90%);
            transform: translate(-50%, -90%);
  }
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: center center no-repeat;
  background-size: cover;
  opacity: 0;
  display: block;
}
.bg.bg-loaded {
  -webkit-animation: fadeIn 1s ease both;
          animation: fadeIn 1s ease both;
  opacity: 1;
}
.bg img {
  display: none;
}
.btn {
  position: relative;
  display: inline-block;
  font-family: 'PT Sans', sans-serif;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 26px;
  transition: 0.3s;
  background-color: #003764;
  color: #fff;
  text-align: center;
  font-weight: bold;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #003764;
}
@media (min-width: 720px) {
  .btn {
    padding: 20px 60px;
    font-size: 24px;
    line-height: 28px;
  }
}
.btn span {
  margin-left: 10px;
  font-size: 24px;
  line-height: 12px;
  position: relative;
  top: 3px;
}
.btn:hover {
  background-color: #fff;
  color: #151e2f;
}
.btn.btn--white {
  background-color: #fff;
  color: #003764;
}
@media (min-width: 720px) {
  .btn.btn--small {
    padding: 10px 30px;
    font-size: 16px;
    line-height: 22px;
  }
}
.btn.btn--colorsecondary {
  background-color: #e2201c;
  border-color: #e2201c;
}
.btn.btn--colorsecondary:hover {
  background-color: #fff;
  color: #003764;
}
.btn.btn--gray {
  background-color: #8d8d8d;
  border-color: #8d8d8d;
  color: #fff;
}
.btn.btn--gray:hover {
  background-color: #fff;
  color: #003764;
}
form,
.form {
  position: relative;
}
input,
select,
textarea {
  display: block;
  width: 100%;
  padding: 10px;
  vertical-align: top;
  background: #fff;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  font-weight: 100;
}
input:focus,
select:focus,
textarea:focus {
  border-color: color;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#checkbox,
input[type=radio] {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  padding: 0;
  border: none;
  background: #f7f4f4 url("./img/icons/icon-checked.png") 50% -100% no-repeat;
  position: relative;
  top: -2px;
}
#checkbox:checked,
input[type=radio]:checked {
  background-color: #e2201c;
  background-position: 50% 50%;
  border-color: #f7f4f4;
}
input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
}
select {
  display: inline-block;
  border: none;
  opacity: 1;
  padding: 10px 15px 10px 15px;
  background-color: #f7f4f4;
  margin-bottom: 0;
 /* background: url("./img/icon-select.png") 100% 50% no-repeat; */
}
textarea {
  height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.form__element {
  position: relative;
  display: block;
}
.form__element:hover {
  cursor: text;
}
.form__element .form__title {
  color: #151e2f;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  z-index: 5;
  margin-bottom: 5px;
}
.form__element .form__input {
  position: relative;
  border: 1px solid #f7f4f4;
  z-index: 2;
  background-color: #f7f4f4;
}
.form__element .form__input input,
.form__element .form__input select,
.form__element .form__input textarea {
  background-color: #f7f4f4;
  color: #8d8d8d;
  font-weight: 500;
  border: 0;
  padding: 5px 10px;
  padding-left: 10px;
  padding-right: 40px;
  border: none !important;
}
.form__element .form__radios {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
@media (min-width: 720px) {
  .form__element .form__radios {
    -ms-flex-direction: row;
        flex-direction: row;
  }
}
.form__element .form__radios .form__radio {
  margin-bottom: 20px;
}
.form__buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
@media (min-width: 720px) {
  .form__buttons {
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
}
.form__buttons.form__buttons--login {
  display: -ms-flexbox;
  display: flex;
}
@media (min-width: 720px) {
  .form__buttons flex-direction column {
    -ms-flex-direction: column;
        flex-direction: column;
  }
}
.form__buttons .btn {
  margin-bottom: 20px;
}
@media (min-width: 720px) {
  .form__buttons .btn {
    width: 100%;
    margin: 0 10px;
  }
}
section {
  position: relative;
  background-color: transparent;
  color: #151e2f;
  overflow: hidden;
}
section .section__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
section .section__title {
  font-weight: 500;
}
section .section__description {
  font-weight: 300;
  margin-bottom: 25px;
  opacity: 0.7;
}
@media (min-width: 720px) {
  section .section__description {
    line-height: 30px;
    font-size: 18px;
  }
}
section .section__header {
  text-align: center;
  padding-bottom: 20px;
}
section .section__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
section .section__video video {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
section .section__content {
  position: relative;
  color: #151e2f;
  line-height: 26px;
  font-size: 15px;
}
section .section__content>* {
  -webkit-animation: slideFadeUp 0.5s ease backwards;
          animation: slideFadeUp 0.5s ease backwards;
}
section .section__content>*:nth-child(1) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
section .section__content>*:nth-child(2) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
section .section__content>*:nth-child(3) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
section .section__content>*:nth-child(4) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
section .section__content>*:nth-child(5) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
section .section__content.section--left {
  text-align: left;
}
section .section__content.section--right {
  text-align: right;
}
section .section__content.section--center {
  text-align: center;
}
section .section__content.section--justify {
  text-align: justify;
}
section .section__content blockquote p {
  line-height: 26px;
  font-size: 16px;
  color: #151e2f;
}
@media (min-width: 720px) {
  section .section__content blockquote p {
    font-size: 18px;
  }
}
section .section__content ul {
  margin-bottom: 20px;
}
section .section__content ul li {
  padding: 5px 0px;
}

@media (min-width: 480px) {
  section .section__content.section__content--2col {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
  }
}
@media (min-width: 480px) {
  section .section__content.section__content--3col {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
  }
}
@media (min-width: 720px) {
  section .section__content.section__content--3col {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
}
@media (min-width: 720px) {
  section .section__flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: center;
        align-items: center;
  }
}
section .section__wrap {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 20px;
}
@media (min-width: 720px) {
  section .section__wrap {
    padding: 80px 40px;
  }
}
section .section__wrap.section__wrap--main {
  display: -ms-flexbox;
  display: flex;
  padding: 100px 20px 50px;
  height: 85vh;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
}
@media (min-width: 720px) {
  section .section__wrap.section__wrap--main {
    height: 90vh;
    padding: 100px 40px 50px;
  }
}
section .section__wrap.section__wrap--header {
  padding: 80px 20px;
  padding-top: 120px;
}
@media (min-width: 720px) {
  section .section__wrap.section__wrap--header {
    padding: 160px 40px;
  }
}
@media (min-width: 720px) {
  section .section__wrap.section__wrap--sm {
    padding: 40px;
  }
}
@media (min-width: 720px) {
  section .section__wrap.section__wrap--xs {
    padding: 20px;
  }
}
@media (min-width: 720px) {
  section .section__wrap.section__wrap--lg {
    padding: 60px 40px;
  }
}
@media (min-width: 960px) {
  section .section__wrap.section__wrap--lg {
    padding: 80px 40px;
  }
}
section .section__wrap.section--sm {
  max-width: 900px;
}
section .section__wrap.section--md {
  max-width: 1060px;
}
section .section__wrap.section--lg {
  max-width: 1200px;
}
section.section--dark {
  background: #151e2f;
  color: #fff;
}
section.section--light {
  background: #f7f4f4;
  color: #151e2f;
}
section.section--white {
  background: #fff;
  color: #151e2f;
}
section.section--white {
  background: #fff;
  color: #151e2f;
}
section.section--color-primary {
  background: #003764;
  color: #fff;
}
section.section--color-secundary {
  background: #003764;
  color: #fff;
}
section.section--gray {
  background: #8d8d8d;
  color: #fff;
}
section.section--grayten {
  background: grayter;
  color: #fff;
}
section.section--main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100vh;
  background-color: #003764;
  border-top: 20px solid #e2201c;
  border-bottom: 20px solid #003764;
}
section.section--main .section__bg {
  opacity: 0.7;
}
section.section--main .section__logo {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 3;
  padding: 20px;
}
section.section--main .section__logo img {
  width: 110px;
}
section.section--main .section__box {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px;
}
@media (min-width: 720px) {
  section.section--main .section__box {
    padding: 40px 40px;
  }
}
section.section--main .section__box .section__flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
}
@media (min-width: 720px) {
  section.section--main .section__box .section__flex {
    -ms-flex-direction: row;
        flex-direction: row;
  }
}
section.section--main .section__box .section__flex .section__title {
  font-size: 26px;
  line-height: 29px;
  margin-bottom: 40px;
}
@media (min-width: 720px) {
  section.section--main .section__box .section__flex .section__title {
    font-size: 32px;
    line-height: 36px;
  }
}
@media (min-width: 720px) {
  section.section--main .section__box .section__flex .section__title {
    margin-bottom: 0;
    max-width: 50%;
  }
}
section.section--main .section__box .section__flex .btn {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 720px) {
  section.section--main .section__box .section__flex .btn {
    width: initial;
  }
}
section.section--form {
  background-color: #003764;
  border-top: 20px solid #e2201c;
  border-bottom: 20px solid #003764;
}
section.section--form .section__wrap {
  width: 100%;
  padding: 120px 20px;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
@media (min-width: 720px) {
  section.section--form .section__wrap {
    padding: 80px 20px;
  }
}
section.section--form .section__logo {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 3;
  padding: 20px;
}
section.section--form .section__logo img {
  width: 80px;
}
section.section--form .section__bg {
  opacity: 0.7;
}
section.section--form .section__form {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  border-radius: 20px;
  z-index: 2;
  background-color: rgba(234,234,234,0.95);
}
section.section--form .section__form.section__form--login {
  text-align: center;
}
section.section--form .section__form.section__form--login .form__element {
  text-align: left;
}
section.section--form .section__form.section__form--thanks {
  text-align: center;
}
section.section--form .section__form.section__form--thanks .section__icon {
  margin-bottom: 20px;
}
section.section--form .section__form.section__form--thanks .section__icon img {
  width: 80px;
}
section.section--form .section__form .form {
  max-width: 600px;
  margin: 0 auto;
}
section.section--form .section__form .section__content {
  margin-bottom: 40px;
}
section.section--form .section__form .section__content .section__title {
  padding-left: 20px;
  color: #003764;
  font-weight: bold;
}
section.section--form .section__form .section__content .section__title:after {
  content: '';
  width: 10px;
  height: 10px;
  line-height: 10px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e2201c;
  position: absolute;
  top: 7px;
  left: 0px;
}


.bg.bg-loaded{
  background-image: url("img/uploads/1.jpg");
}


.error{
  color: red;
}

.customTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customTable td, .customTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.customTable tr:nth-child(even){background-color: #f2f2f2;}

.customTable tr:hover {background-color: #ddd;}

.customTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #003764;
  color: white;
}

.attention{
  margin-top: 31px;
}

.materialRadios input{
  border: 1px solid #000000;
}

.row{
  margin-bottom:15px
}

.buttonsModal{
  display: flex;
  justify-content: space-between;
}

.row-inline{
  display: flex;
}

@media print{
  .noprint{
      display:none;
  }
}

.header_close_sesion{
  display: flex;
  width: 90vw;
  justify-content: space-between;
}

.phone input{
  padding-left: 55px !important;
  }
  
  .react-tel-input .form-control{
    width: auto !important;
  }